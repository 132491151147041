@import '@standby/common-ui/color.scss';
.searchBox {
  margin-top: 12px;
  .searchInput {
    width: 318px;
    height: 32px;
    border: 1px solid $neutral400;
    border-radius: 4px;
    background-image: url('/images/tableSerachIcon.svg');
    background-repeat: no-repeat;
    background-position: left 12px center;
    padding-left: 40px;
  }
  .submit {
    width: 64px;
    height: 32px;
    border: 1px solid $neutral400;
    border-radius: 4px;
    background-color: $neutral100;
    color: $neutral800;
    font-weight: 400;
    font-size: 13px;
    margin-left: 6px;
  }
}
.alimtalkCenterList {
  border-top: 1px solid $neutral400;
  width: 100%;
  margin-top: 13px;
  th,
  td {
    border-bottom: 1px solid $neutral400;
    border-left: 1px solid $neutral400;
    &:nth-last-child(1) {
      border-right: 1px solid $neutral400;
    }
  }
  td {
    border-color: $neutral300 !important;
    font-size: 14px;
    font-weight: 400;
    color: $neutral800;
    padding: 5px 19px;
  }
  th {
    font-size: 16px;
    font-weight: 400;
    color: $neutral800;
    background-color: $neutral200;
    &.mainTh {
      font-size: 16px;
      font-weight: 400;
      color: $neutral900;
      height: 35px;
    }
    &.subTh {
      font-size: 12px;
      font-weight: 400;
      color: $neutral800;
      height: 24px;
    }
  }
  .sendBtn {
    border: 1px solid $neutral400;
    background-color: $neutral100;
    width: 82px;
    height: 28px;
    border-radius: 2px;
    color: $main300;
    font-size: 13px;
    font-weight: 400;
  }
  .select {
    border: none;
    font-size: 14px;
    font-weight: 400;
    color: $neutral800;
    appearance: none;
    padding-right: 10px;
    width: 195px;
    letter-spacing: -0.3px;
    background-image: url('/images/talkSelectIcon.svg');
    background-position: right center;
    background-repeat: no-repeat;
  }
  .targetList {
    li {
      margin-top: 4px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}

@import '@standby/common-ui/color.scss';
#header {
  background-color: $primary900;
  height: 60px;
  color: $neutral100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  .content {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      .logoIcon {
        width: 30px;
        height: 30px;
        background-color: $neutral100;
        border-radius: 4px;
        box-shadow: 0px 1px 4px 2px rgba(13, 61, 111, 0.5);
        margin-right: 10px;
        display: flex;
        justify-content: center;
        img {
          width: 24px;
        }
      }
      position: absolute;
      left: 36px;
      top: 15px;
      display: flex;
      align-items: center;
      color: $neutral100;
      font-weight: 400;
      font-size: 16px;
    }
    .menuCover {
      display: inline-block;
      height: 100%;
      display: flex;
      align-items: center;
      &:hover {
        .subMenuBackground {
          height: 80px;
        }
        ~ .menuBackgorund {
          visibility: visible;
          background: rgba(153, 153, 153, 0.3);
        }
      }
      .mainMenu {
        width: 1128px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        height: 100%;
        z-index: 1;
        background-color: $primary900;
        li {
          width: 120px;
          text-align: center;
        }
        li.active span {
          position: relative;
          font-weight: 400;
          &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            height: 2px;
            display: inline-block;
            background-color: $neutral100;
            bottom: -6px;
          }
        }
      }
      .subMenuBackground {
        transition: 0.5s;
        position: absolute;
        left: 0;
        top: 60px;
        height: 0;
        overflow: hidden;
        width: 100%;
        z-index: 200;
        background-color: $neutral100;
        box-shadow: 1px 1px 3px 1px rgba(153, 153, 153, 0.2);
        .subMenu {
          margin: 0 auto;
          width: 1128px;
          display: flex;
          justify-content: space-around;
          ul {
            padding-bottom: 20px;
            li {
              width: 120px;
              text-align: center;
              font-size: 14px;
              margin-top: 16px;
              line-height: 100%;
              &.active {
                a {
                  color: $main500;
                  font-weight: 500;
                }
              }
              a,
              .yet {
                color: $neutral900;
                font-weight: 500;
              }
              .yet {
                color: $neutral400 !important;
                cursor: not-allowed;
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .menuBackgorund {
      position: fixed;
      width: 100%;
      top: 60px;
      height: calc(100% - 60px);
      background: rgba(153, 153, 153, 0);
      visibility: hidden;
      transition: 0.3s;
    }
    .side {
      display: flex;
      align-items: center;
      position: absolute;
      right: 36px;
      top: 18px;
      .buttonIcon {
        background-color: transparent;
        color: $neutral100;
        font-size: 15px;
        font-weight: 400;
        background-repeat: no-repeat;
        background-position: right 0px top 3px;
        padding-right: 20px;
        &.mypage {
          background-image: url('/images/userIcon.svg');
          margin-left: 24px;
        }
        &.notify {
          background-image: url('/images/notify_new_disabled.svg');
          &.new {
            background-image: url('/images/notify_new.svg');
          }
        }
      }
      .guide {
        width: 24px;
        height: 24px;
        border: none;
        background-color: transparent;
        background-image: url('/images/guideIcon.png');
        position: relative;
        top: 2px;
        display: inline-block;
        margin-left: 32px;
        &:before {
          content: '';
          position: absolute;
          display: inline-block;
          width: 1px;
          height: 24px;
          background-color: $neutral400;
          left: -16px;
          top: 0;
        }
      }
      .logout {
        width: 16px;
        height: 16px;
        border: none;
        background-color: transparent;
        background-image: url('/images/logoutIcon.svg');
        position: relative;
        top: 3px;
        margin-left: 24px;
      }
    }
  }
}

.notifyWrap {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($neutral600, 0);
  z-index: 5000;
  visibility: hidden;
  transition: 0.3s;
  .notifyRelative {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    .notifyBox {
      width: 400px;
      background-color: $neutral100;
      position: absolute;
      top: 0;
      right: -400px;
      bottom: 0;
      box-shadow: -3px 0px 4px 1px rgba(0, 0, 0, 0.12);
      transition: 0.3s;
      transition-timing-function: ease-in;
      .title {
        font-size: 16px;
        font-weight: 500;
        height: 57px;
        line-height: 57px;
        border-bottom: 1px solid $neutral400;
        padding-left: 24px;
      }
      .empty {
        color: $neutral800;
        font-size: 15px;
        line-height: 140%;
        font-weight: 400;
        padding-top: 42px;
        text-align: center;
      }
      .list {
        padding: 24px;
        padding-right: 0px;
        .inside {
          padding-right: 24px;
        }
        .scrollArea {
          height: calc(100vh - 153px);
        }
        .confirm {
          border: 1px solid $neutral300;
          border-radius: 4px;
          padding: 20px;
          margin-top: 20px;
          &:nth-child(1) {
            margin-top: 0;
          }
          .detail {
            font-size: 15px;
            font-weight: 400;
            color: $neutral900;
            line-height: 21px;
            padding-left: 26px;
            background-image: url('~@standby/common-ui/components/asset/Icon/icon_exclamation_noti.svg');
            background-repeat: no-repeat;
            background-position: left top 2px;
            min-height: 22px;
          }
          .time {
            padding-left: 26px;
            font-weight: 400;
            color: $neutral700;
            line-height: 12px;
            font-size: 12px;
            margin-top: 6px;
          }
          .btnArea {
            margin-top: 8px;
            display: flex;
            justify-content: end;
            button {
              margin-left: 8px;
              &:nth-child(1) {
                margin-left: 0;
              }
            }
          }
        }
        .readList {
          .readType {
            .detail {
              font-size: 15px;
              line-height: 21px;
              font-weight: 400;
              color: $neutral800;
              letter-spacing: -2%;
              padding-left: 10px;
              position: relative;
              &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 4px;
                background-color: $main100;
                left: 0;
                top: 9px;
              }
              a {
                text-decoration: underline;
              }
            }
            .time {
              padding-left: 10px;
              font-weight: 400;
              color: $neutral600;
              line-height: 12px;
              font-size: 12px;
              margin-top: 6px;
            }
            border-bottom: 1px solid $neutral300;
            padding-bottom: 16px;
            padding-top: 16px;
            &:nth-child(1) {
              padding-top: 0;
            }
            &.read {
              .detail {
                color: $neutral500;
                a {
                  color: inherit;
                }
                &:before {
                  background-color: $neutral500;
                }
              }
              .time {
                color: $neutral400;
              }
            }
          }
        }
      }
    }
  }
  &.open {
    background-color: rgba($neutral600, 0.7);
    visibility: visible;
    .notifyRelative {
      .notifyBox {
        right: 0;
      }
      .background {
        width: calc(100% - 400px);
        top: 0;
        position: absolute;
        bottom: 0;
        cursor: pointer;
      }
    }
  }
}

@import '@standby/common-ui/color.scss';

.wrap {
  padding: 36px 50px;
  width: 675px;
  height: 488px;
  .row {
    display: flex;
    align-items: center;
    margin-top: 12px;
    &:nth-child(1) {
      margin-top: 0;
    }
    .title {
      width: 127px;
      font-size: 16px;
      font-weight: 400;
      color: $neutral900;
      align-self: flex-start;
      position: relative;
      top: 10px;
    }
    .searchBtn {
      border: 1px solid $main400;
      border-radius: 4px;
      font-size: 14px;
      color: $main500;
      font-weight: 400;
      width: 84px;
      height: 42px;
      margin-left: 8px;
      background-color: transparent;
    }
    .exContent {
      position: relative;
      padding-bottom: 24px;
      .ex {
        position: absolute;
        bottom: 0;
        left: 0;
        font-size: 14px;
        color: $neutral600;
        font-weight: 400;
        line-height: 18px;
        &:before {
          content: '*';
          position: relative;
          top: 3px;
          margin-right: 4px;
        }
      }
    }
  }
  .selectedList {
    width: 100%;
    height: 200px;
    border: 1px solid $neutral300;
    border-radius: 4px;
    margin-top: 24px;
    .empty {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      color: $neutral800;
      line-height: 24px;
      margin-top: 62px;
      padding-top: 22px;
      background-image: url('/images/emptyIcon.svg');
      background-repeat: no-repeat;
      background-position: top center;
    }
    .radioTitle {
      font-size: 15px;
      color: $neutral800;
    }
    .radioSub {
      color: $neutral600;
    }
    .businessList {
      padding: 0 16px;
      li {
        padding: 16px 0;
        border-bottom: 1px solid $neutral300;
        &:nth-last-child(1) {
          margin-bottom: 10px;
        }
      }
    }
    .scrollArea {
      height: 100%;
    }
  }
  .btnWrap {
    text-align: center;
    margin-top: 24px;
    button {
      display: inline-block;
    }
  }
}

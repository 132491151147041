@import '@standby/common-ui/color.scss';

.modalContent {
  width: 675px;
  padding: 36px 50px;
  > .topText {
    font-size: 16px;
    color: $neutral800;
    font-weight: 400;
    .important {
      font-weight: 500;
    }
    padding-bottom: 24px;
    border-bottom: 1px solid $neutral300;
  }
  > .title {
    font-size: 16px;
    font-weight: 500;
    color: $neutral900;
    margin-top: 36px;
    line-height: 100%;
  }
  > ul {
    > li {
      display: flex;
      align-items: flex-start;
      > .title {
        width: 144px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        color: $neutral800;
        position: relative;
        top: 7px;
      }
      margin-top: 24px;
    }
  }
}
.dateContent {
  font-size: 15px;
  font-weight: 400;
  color: $neutral800;
  margin-top: 36px;
}

.buttonArea {
  margin-top: 36px;
  text-align: center;
}
.ex {
  color: $neutral600;
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
  position: relative;
  padding-left: 9px;
  margin-top: 8px;
  &::before {
    content: '*';
    position: absolute;
    left: 0;
    top: 4px;
  }
}

.saveBtn {
  height: 48px;
  background-color: #fff;
  border: 1px solid $main400;
  border-radius: 4px;
  &:disabled {
    border-color: $neutral300;
    background-color: $neutral200;
    &.send {
      span {
        background-image: url('/images/sendTalkIcon_disabled.svg');
      }
    }
    span {
      color: $neutral400;
    }
  }
  &.send {
    span {
      background-image: url('/images/sendTalkIcon.svg');
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 20px;
    }
  }
  span {
    color: $main500;
    font-size: 14px;
    font-weight: 400;
  }
}
.exList {
  li {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.5px;
    line-height: 130%;
    margin-top: 8px;
    color: $neutral600;
    position: relative;
    padding-left: 10px;
    &:before {
      content: '*';
      display: inline-block;
      top: 3px;
      left: 0;
      position: absolute;
    }
    &:nth-child(1) {
      margin-top: 0;
    }
  }
}

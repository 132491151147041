@import '@standby/common-ui/color.scss';
.fold {
  .title {
    button {
      color: $neutral600;
      background-color: transparent;
      font-size: 16px;
      text-decoration: underline;
      font-weight: 400;
      background-image: url('/images/foldOepnIcon.svg');
      background-position: right top 10px;
      background-repeat: no-repeat;
      padding-right: 17px;
    }
    &.opened {
      button {
        color: $neutral800;
        background-image: url('/images/foldCloseIcon.svg');
      }
    }
  }
  .content {
    margin-top: 2px;
  }
}

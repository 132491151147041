@import '@standby/common-ui/color.scss';

.ex {
  color: $neutral500;
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
  position: relative;
  padding-left: 9px;
  &::before {
    content: '*';
    position: absolute;
    left: 0;
    top: 4px;
  }
}
.businessHome {
  width: 940px;
  margin: 36px auto;
  > .head {
    border-bottom: 1px solid $neutral700;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    > .title {
      font-weight: 500;
      font-size: 22px;
      line-height: 22px;
      padding-bottom: 16px;
    }
    .ex {
      margin-right: 130px;
      margin-top: 7px;
    }
  }
  > .contentList {
    > li {
      padding-top: 24px;
      padding-bottom: 26px;
      border-bottom: 1px solid $neutral300;
      > .content {
        display: flex;
        > .title {
          font-size: 18px;
          font-weight: 500;
          color: $primary800;
          width: 190px;
        }
        > .content2 {
          > .progressList {
            > li {
              border-bottom: 1px solid $neutral300;
              padding-bottom: 26px;
              padding-top: 24px;
              &:nth-child(1) {
                padding-top: 0;
              }
              &:nth-last-child(1) {
                border-bottom: none;
                padding-bottom: 0;
              }
              &.yet {
                > .title {
                  color: $neutral500;
                  font-weight: 400;
                  font-size: 18px;
                }
                > .empty {
                  background-color: $neutral200;
                  width: 750px;
                  height: 90px;
                  text-align: center;
                  line-height: 90px;
                  margin-top: 20px;
                }
              }
              > .title {
                > .link {
                  font-weight: 500;
                  font-size: 18px;
                  color: $neutral800;
                  text-decoration: underline;
                  background-image: url('/images/titleLinkIcon.svg');
                  background-repeat: no-repeat;
                  background-position: right top 6px;
                  padding-right: 20px;
                  margin-right: 16px;
                }
              }
              > .progressItemList {
                display: flex;
                margin-top: 20px;
                > .item {
                  width: 171px;
                  height: 90px;
                  border: 1px solid $neutral500;
                  border-radius: 4px;
                  margin-left: 22px;
                  display: flex;
                  flex-flow: column;
                  align-items: center;
                  justify-content: center;
                  &.minWidth {
                    width: 134px;
                  }
                  &:nth-child(1) {
                    margin-left: 0;
                  }
                  position: relative;
                  &::after {
                    position: absolute;
                    display: inline-block;
                    content: '';
                    background-image: url('/images/progressListIcon.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    width: 18px;
                    height: 60px;
                    top: 13px;
                    right: -21px;
                  }
                  &:nth-last-child(1)::after {
                    content: none;
                  }
                  &.selected {
                    border-color: $main400;
                    background-color: $main000;
                    .status {
                      color: $main500;
                    }
                    & ~ .item {
                      border-color: $neutral300;
                      .status {
                        color: $neutral600;
                      }
                    }
                    .date,
                    .trueStatue {
                      color: $neutral800;
                    }
                  }
                  .status {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 130%;
                    color: $neutral800;
                    text-align: center;
                    letter-spacing: -0.6px;
                  }
                  .date {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 130%;
                    margin-top: 4px;
                    color: $neutral600;
                  }
                  .trueStatue {
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 130%;
                    margin-top: 2px;
                    color: $neutral600;
                  }
                }
              }
            }
          }
          > .contentList {
            > li {
              font-size: 16px;
              font-weight: 400;
              color: $neutral800;
              display: flex;
              line-height: 100%;
              margin-top: 16px;
              &:nth-child(1) {
                margin-top: 0;
              }
              > .title {
                width: 144px;
              }
              > .content {
                .userList {
                  > li {
                    margin-top: 12px;
                    > span {
                      margin-left: 12px;
                      &:nth-child(1) {
                        margin-left: 0;
                      }
                    }
                    &:nth-child(1) {
                      margin-top: 0;
                    }
                    > .position {
                      min-width: 110px;
                      display: inline-block;
                      &.empty {
                        color: $neutral500;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.copy {
  color: $main300;
  cursor: pointer;
  text-decoration: underline;
}
.selectedSmall {
  border: 1px solid $neutral400;
  border-radius: 4px;
  height: 28px;
  padding-left: 10px;
  padding-right: 34px;
  color: $neutral800;
  font-size: 14px;
  font-weight: 400;
  appearance: none;
  width: 155px;
  letter-spacing: 1;
  background-image: url('/images/select_mini_icon.svg');
  background-repeat: no-repeat;
  background-position: right 8px center;
}
.editBtn {
  width: 16px;
  height: 16px;
  background-image: url('/images/editBtnIcon.svg');
  vertical-align: top;
  position: relative;
  top: 1px;
  background-color: transparent;
}
.customerMoreView {
  width: 213px;
  border: 1px solid $neutral400;
  border-radius: 4px;
  margin-top: 25px;
  line-height: 100%;
  font-size: 16px;
  font-weight: 400;
  color: $neutral800;
  > button {
    padding-left: 20px;
    background-color: transparent;
    border: none;
    line-height: 100%;
    font-size: 16px;
    font-weight: 400;
    color: $neutral800;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-image: url('/images/moreViewClose.svg');
    background-position: left 177px top 14px;
    background-repeat: no-repeat;
  }
  &.disabled {
    border-color: $neutral400;
    background-color: $neutral200;
    > button {
      color: $neutral500;
      background-image: url('/images/moreViewDisabled.svg');
      cursor: not-allowed;
    }
  }
  &.opened {
    width: 750px;
    > button {
      background-image: url('/images/moreViewOpen.svg'), url('/images/moewViewOpenedClose.svg');
      padding-top: 20px;
      padding-bottom: 20px;

      background-position:
        left 177px top 21px,
        top 18px right 20px;
    }
  }
}
.customerMoreViewContent {
  padding: 0 20px;
  padding-bottom: 20px;
  > ul {
    margin-top: 17px;
    &:nth-child(1) {
      margin-top: 0;
    }
    > li {
      border-bottom: 1px solid $neutral300;
      &.listTitle {
        height: 36px;
        border-top: 1px solid $neutral300;
        background-color: $neutral200;
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        padding-left: 8px;
        line-height: 36px;
      }
      &.listItem {
        line-height: 130%;
        font-size: 14px;
        font-weight: 400;
        color: $neutral800;
        display: flex;
        padding: 11px 8px;
        > .title {
          color: $neutral600;
          width: 225px;
          flex-shrink: 0;
          display: flex;
          align-items: flex-start;
          > .number {
            width: 22px;
            display: inline-block;
          }
        }
        > .content {
          .editSide {
            margin-right: 4px;
          }
          &.fullWidth {
            width: 100%;
          }
          &.personList {
            display: flex;
            flex-wrap: wrap;
            margin-top: -10px;
            > .person {
              line-height: 130%;
              margin-right: 12px;
              margin-top: 10px;
            }
          }
          > .detailItemList {
            flex: 1;
            > li {
              flex: 1;
              margin-top: 10px;
              &:nth-child(1) {
                margin-top: 0;
              }
              .title {
                width: 103px;
                flex-shrink: 0;
              }
              &.flexList {
                display: flex;
              }
              .copy {
                margin-left: 8px;
                &:nth-child(1) {
                  margin-left: 0;
                }
              }
              .copyBtn {
                margin-left: 16px;
              }
            }
          }
          .detailTable {
            width: 100%;
          }
          .circleList {
            > li {
              margin-top: 4px;
              position: relative;
              padding-left: 9px;
              &:nth-child(1) {
                margin-top: 0;
              }
              &:before {
                content: '';
                display: inline-block;
                width: 3px;
                height: 3px;
                display: inline-block;
                background-color: $neutral800;
                border-radius: 3px;
                position: absolute;
                top: 8px;
                left: 0;
              }
            }
          }
          .businessTypeAndItemList {
            > li {
              &:nth-child(odd) {
                margin-top: 3px;
              }
              &:nth-child(1) {
                margin-top: 0;
              }
              .index {
                display: inline-block;
                width: 22px;
              }
            }
          }
        }
      }
    }
  }
}
.downloadDisabled {
  color: $neutral500;
  cursor: not-allowed;
  .downloadLink {
    background-image: url('/images/downloadLinkIconDisabled.svg');
    pointer-events: none;
  }
}
.downloadLink {
  background-image: url('/images/downloadLinkIcon.svg');
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 4px;
  background-color: transparent;
}
.pageArrowBtn {
  color: $main500;
  font-size: 14px;
  background-color: transparent;
  font-weight: 400;
  background-image: url('/images/mainColorArrow.svg');
  background-repeat: no-repeat;
  background-position: right top 5px;
  padding-right: 11px;
  text-decoration: underline;
  margin-left: 16px;
}
.pageTopBtn {
  position: absolute;
  right: 0;
  top: 6px;
}

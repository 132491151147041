@import '@standby/common-ui/color.scss';
.result {
  color: $neutral800;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  position: relative;
  .btnArea {
    position: absolute;
    top: -6px;
    right: 0;
    button {
      min-width: 79px;
      padding: 0 15px;
      height: 32px;
      border: $neutral400 1px solid;
      border-radius: 4px;
      background-color: $neutral100;
      font-weight: 400;
      color: $neutral800;
      font-size: 14px;
      margin-right: 8px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
      &:disabled {
        background-color: $neutral200;
        cursor: not-allowed;
        color: $neutral500;
      }
    }
  }
}
.openProcess {
  width: 134px;
  height: 36px;
  background-color: $main400;
  border-radius: 4px;
  color: $neutral100;
  font-weight: 400;
  font-size: 14px;
}
.pageTitle {
  position: relative;
  .pageTopBtn {
    position: absolute;
    right: 0;
    top: 6px;
  }
}
.confirmList {
  margin-top: 10px;
  li {
    font-size: 14px;
    font-weight: 400;
    color: $neutral600;
    padding-left: 10px;
    position: relative;
    margin-top: 4px;
    &:nth-child(1) {
      margin-top: 0;
    }
    &:before {
      content: '*';
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
}
.confirmReason {
  display: flex;
  margin-top: 24px;
  > .title {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;
    color: $neutral800;
    margin-right: 12px;
  }
  > .content {
    flex: 1;
    textarea {
      width: 100%;
      height: 78px;
      resize: none;
      border: 1px solid $neutral400;
      border-radius: 4px;
      padding: 11px 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      color: $neutral800;
    }
  }
}
.businessTypeAndItem {
  margin-top: 6px;
  display: flex;
  align-items: center;
  &:first-child {
    margin-top: 12px;
  }
  .type {
    display: inline-block;
    width: 47px;
    flex-shrink: 1;
    margin-right: 4px;
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.56px;
    .index {
      display: inline-block;
      width: 21px;
    }
  }
  .item {
    display: inline-block;
    width: 47px;
    flex-shrink: 1;
    padding-left: 21px;
    margin-right: 4px;
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.56px;
  }
}

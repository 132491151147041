.BusinessNameTimeline {
  display: flex;
  align-items: center;
  .historyBtn {
    width: 16px;
    height: 16px;
    background-image: url('/images/history_Icon.svg');
    background-color: transparent;
    margin-left: 8px;
  }
}

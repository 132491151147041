@charset "utf-8";
/*
* @ RESET CSS
* -------------------------------
*/
/* html, body, div, p, span, strong, b, em, iframe, pre,
h1, h2, h3, h4, h5, h6,
img, dl, dt, dd, button,
fieldset, form, legend, label,
table, caption, thead, tbody, tfoot, tr, th, td,
ul, ol, li, a, input, select, textarea {margin: 0;padding: 0; border: 0 none; } */
html,
body,
div,
p,
span,
strong,
b,
em,
iframe,
pre,
h1,
h2,
h3,
h4,
h5,
h6,
img,
dl,
dt,
dd,
button,
fieldset,
form,
legend,
label,
table,
caption,
thead,
tbody,
tfoot,
tr,
th,
td,
ul:not(.listStyle),
li,
a {
  margin: 0;
  padding: 0;
  border: 0 none;
}

body {
  margin: 0;
  font-family: 'Noto Sans KR', MalgunGothic, '맑은 고딕', '돋움', Dotum, AppleGothic, Sans-serif, Arial;
  overflow-y: scroll;
  background: #fff;
}

button {
  cursor: pointer;
}
ul:not(.listStyle) > li {
  list-style: none;
}
em,
address {
  font-style: normal;
}
img {
  border: 0 none;
  font-size: 0;
  line-height: 0;
}
sup {
  position: relative;
  top: 2px;
  font-size: 11px;
  line-height: 100%;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
caption {
  overflow: hidden;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
}
th,
td {
  vertical-align: middle; /* white-space: nowrap */
}

a {
  color: #000;
  text-decoration: none;
  line-height: 1;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
}
*,
html {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: 'Noto Sans KR', MalgunGothic, '맑은 고딕', '돋움', Dotum, AppleGothic, Sans-serif, Arial;
  outline: none;
}
*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.clear:after {
  content: '';
  display: block;
  clear: both;
}

.terms a {
  text-decoration: underline;
  color: #4e73f6;
}
.terms table {
  border-collapse: collapse;
}
.terms th,
.terms td {
  border: 1px solid #252526;
  padding: 0 5px;
}

@import '@standby/common-ui/color.scss';
.fileUpload {
  .drop {
    width: 212px;
    height: 100px;
    border: 1px dashed $neutral300;
    text-align: center;
    .info {
      color: $neutral600;
      font-weight: 400;
      font-size: 13px;
      line-height: 15.6px;
      margin-top: 12px;
    }
    button {
      margin-top: 8px;
      width: 188px;
      strong {
        font-weight: 500;
      }
    }
  }
  .uploadExplain {
    font-size: 14px;
    line-height: 18.2px;
    margin-top: 6px;
    color: $neutral600;
    font-weight: 400;
    padding-left: 10px;
    position: relative;
    &:before {
      position: absolute;
      content: '*';
      top: 3px;
      left: 0;
    }
  }
  input {
    display: none;
  }
  .uploaded {
    width: 212px;
    height: 36px;
    border: 1px solid $neutral300;
    color: $neutral700;
    font-weight: 400;
    font-size: 14px;
    line-height: 34px;
    padding-left: 30px;
    border-radius: 4px;
    background-image: url('/images/uploadFileIcon.svg');
    background-position: left 8px center;
    background-repeat: no-repeat;
    position: relative;
    .limitText {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: calc(100% - 40px);
      display: inline-block;
    }
    &.download {
      cursor: pointer;
      color: $main300;
      background-image: url('/images/downloadFileIcon.svg');
    }
  }
  .multipleUploaded {
    position: relative;
    width: 212px;
    border: 1px solid $neutral300;
    border-radius: 4px;
    .title {
      height: 36px;
      line-height: 36px;
      padding-left: 8px;
      letter-spacing: -0.56px;
      color: $neutral800;
      font-weight: 500;
      font-size: 14px;
      border-bottom: 1px solid $neutral300;
    }
    ul {
      li {
        border-bottom: 1px solid $neutral300;
        margin: 0 8px;
        padding-left: 22px;
        background-image: url('/images/uploadFileIcon.svg');
        background-position: left center;
        background-repeat: no-repeat;
        color: $neutral700;
        font-weight: 400;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 36px;
        line-height: 34px;
        white-space: nowrap;
        width: calc(100% - 16px);
        display: block;
        &:nth-last-child(1) {
          border-bottom: none;
        }
      }
    }
    &.download {
      ul {
        li {
          color: $main300;
          background-image: url('/images/downloadFileIcon.svg');
        }
      }
      .downloadBtn {
        position: absolute;
        right: 30px;
        top: 7px;
        color: $main300;
        font-weight: 400;
        text-decoration: underline;
        padding-left: 20px;
        background-color: transparent;
        background-image: url('/images/downloadLinkIcon.svg');
        background-repeat: no-repeat;
        background-position: left center;
      }
    }
  }
  .deleteBtn {
    width: 16px;
    height: 16px;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 10px;
    right: 8px;
    background-image: url('/images/deleteFileIcon.svg');
  }
}

@import '@standby/common-ui/color.scss';

.gridBtn {
  border: 1px solid $neutral400;
  background-color: $neutral100;
  color: $main300;
  border-radius: 2px;
  height: 28px;
  padding: 0 16px;
}

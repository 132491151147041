@import '@standby/common-ui/color.scss';

.modalContent {
  width: 675px;
  padding: 36px 50px;
  > ul {
    > li {
      display: flex;
      align-items: flex-start;
      > .title {
        width: 144px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        color: $neutral800;
        position: relative;
        top: 7px;
      }
      margin-top: 24px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  .contentText {
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
  }
}
.datepicker {
  input {
    width: 298px;
  }
}
.checkList {
  li {
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.buttonArea {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  > span {
    width: 50%;
  }
}
.saveBtn {
  height: 48px;
  background-color: #fff;
  border: 1px solid $main400;
  border-radius: 4px;
  &:disabled {
    border-color: $neutral300;
    background-color: $neutral200;
    &.send {
      span {
        background-image: url('/images/sendTalkIcon_disabled.svg');
      }
    }
    span {
      color: $neutral400;
    }
  }
  &.send {
    span {
      background-image: url('/images/sendTalkIcon.svg');
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 20px;
    }
  }
  span {
    color: $main500;
    font-size: 14px;
    font-weight: 400;
  }
}

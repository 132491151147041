@import '@standby/common-ui/color.scss';

.modalEdit {
  display: inline-block;
  position: relative;
  .editBtn {
    width: 16px;
    height: 16px;
    background-image: url('/images/editBtnIcon.svg');
    vertical-align: top;
    position: relative;
    top: 1px;
    background-color: transparent;
  }
  .modal {
    border: 1px solid $neutral400;
    padding: 22px 36px 18px 32px;
    position: absolute;
    top: 20px;
    &.left {
      right: 0;
    }
    &.right {
      left: 0;
    }

    background-color: $neutral100;
    z-index: 1;
    display: flex;
    .close {
      background-image: url('/images/modalCloseMini.svg');
      width: 16px;
      height: 16px;
      background-color: transparent;
      position: absolute;
      top: 6px;
      right: 6px;
    }
  }
  .inputText {
    border: 1px solid $neutral400;
    width: 156px;
    height: 36px;
    border-radius: 4px;
    font-size: 13px;
    font-weight: 400;
    padding: 0 10px;
    color: $neutral800;
  }
  .submit {
    border: 1px solid $main400;
    color: $main500;
    font-size: 13px;
    font-weight: 400;
    border-radius: 4px;
    width: 60px;
    height: 36px;
    background-color: $neutral100;
    margin-left: 6px;
  }
}
.select {
  border: 1px solid $neutral400;
  border-radius: 4px;
  height: 36px;
  padding-left: 10px;
  padding-right: 34px;
  color: $neutral800;
  font-size: 13px;
  font-weight: 400;
  appearance: none;
  width: 155px;
  letter-spacing: 1;
  background-image: url('/images/select_mini_icon.svg');
  background-repeat: no-repeat;
  background-position: right 11px center;
  &.selectLong {
    width: 250px;
  }
}
.datepicker {
  input {
    border: 1px solid $neutral400 !important;
    width: 156px !important;
    height: 36px !important;
    border-radius: 4px !important;
    font-size: 13px !important;
    font-weight: 400 !important;
    padding: 0 10px !important;
    color: $neutral800 !important;
  }
}
.price {
  display: flex;
  align-items: center;
  .won {
    font-size: 13px;
    font-weight: 400;
    color: $neutral800;
    margin-left: 4px;
  }
}

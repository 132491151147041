@import '@standby/common-ui/color.scss';

.timeline {
  min-width: 640px;
  padding: 20px;
  margin: 0 auto;
  .submitText {
    position: relative;
    .inputText {
      width: 100%;
      border: 1px solid $neutral400;
      padding: 6px 13px;
      border-radius: 4px;
      padding-right: 40px;
      resize: none;
    }
    .submit {
      width: 14px;
      height: 16px;
      background-image: url('/images/timelineSend_Icon.svg');
      position: absolute;
      background-color: transparent;
      right: 9px;
      top: 9px;
    }
  }
  .content {
    .tab {
      display: flex;
      margin-top: 5px;
      li {
        button {
          background-color: transparent;
          color: $neutral600;
          font-weight: 400;
          font-size: 15px;
          line-height: 15px;
          padding: 7px 16px;
        }
        &.active {
          border-bottom: 2px solid $main300;
          button {
            color: $main300;
            font-weight: 400;
          }
        }
      }
    }
    .list {
      border: 1px solid $neutral300;
      height: calc(100vh - 130px);
      overflow-y: auto;
      .pinList {
        background-color: #fff4e9;
        padding: 5px 20px 5px 10px;
        background-repeat: no-repeat;
        background-position: top 8px left 10px;
        margin-top: 6px;
        margin-left: 6px;
        margin-right: 6px;
        li:hover {
          background-color: #ffe8d3 !important;
        }
      }
      .nomalList {
        padding-top: 10px;
        li {
          padding: 5px 26px 5px 16px;
        }
      }
      li {
        font-size: 14px;
        line-height: 14px;
        padding: 5px 0;
        font-weight: 400;
        display: flex;
        position: relative;
        &:hover:not(.progressLine) {
          background-color: $neutral200;
          .btnGroup {
            display: flex;
          }
        }
        .btnGroup {
          position: absolute;
          right: 5px;
          top: -11px;
          background-color: $neutral100;
          border: 1px solid $neutral300;
          border-radius: 2px;
          width: 45px;
          height: 23px;
          display: none;
          button {
            height: 100%;
            flex: 1;
            background-color: $neutral100;
            background-position: center;
            background-repeat: no-repeat;
            &.pin {
              background-image: url('/images/timelinePin_icon.svg');
            }
            &.delete {
              background-image: url('/images/deleteTimeline_icon.svg');
            }
          }
        }
        &:nth-child(1) {
          margin-top: 0;
        }
        .date {
          font-size: 13px;
          line-height: 13px;
          color: $neutral500;
          flex-shrink: 0;
          min-width: 95px;
          display: inline-block;
        }
        .name {
          color: $neutral500;
          margin-left: 8px;
          flex-shrink: 0;
        }
        .memo {
          white-space: pre-wrap;
          margin-left: 8px;
          color: $neutral800;
          position: relative;
          font-weight: 400;
          line-height: 120%;
          &.important {
            background-color: #fff95f;
            font-weight: 400;
            outline: 3px solid #fff95f;
          }
          &.auto_customer {
            font-weight: 500;
            color: $sub200;
          }
          &.auto_accounting {
            color: #3d0aa9;
            font-weight: 500;
            &:after {
              content: '상태로 변경';
              color: $neutral600;
              font-weight: 400;
              margin-left: 3px;
            }
          }
          &.sendTalk {
            color: $neutral600;
            font-weight: 400;
            .viewTalkContent {
              font-size: 11px;
              line-height: 11px;
              letter-spacing: -3%;
              color: $neutral600;
              font-weight: 400;
              background-color: $neutral100;
              border: 1px solid $neutral500;
              width: 48px;
              height: 16px;
              border-radius: 2px;
              margin-left: 4px;
            }
          }
          .pin {
            width: 9px;
            height: 11px;
            background-image: url('/images/timelinePin_icon.svg');
            background-color: transparent;
            position: absolute;
            right: -11px;
            top: 4px;
          }
        }
      }
    }
  }
  .progressLine {
    position: relative;
    display: flex;
    justify-content: center;
    &::before {
      display: inline-block;
      left: 0;
      right: 0;
      position: absolute;
      height: 1px;
      content: '';
      top: 15px;
      background-color: $neutral300;
    }
    .progressContent {
      display: inline-flex;
      // height: 21px;
      border: 1px solid $neutral300;
      border-radius: 4px;
      padding: 4px 27px;
      align-items: center;
      position: relative;
      z-index: 1;
      background-color: $neutral100;
      .name,
      .memo {
        font-size: 12px;
        margin-left: 5px !important;
      }
    }
  }
  .pinListTitle {
    margin-top: 14px;
    .pinLengthText {
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      color: $neutral800;
      margin-left: 6px;
    }
    .radioCheckInput {
      display: none;
    }
    .radioCheckLabel {
      cursor: pointer;
      width: 36px;
      height: 15px;
      background-color: $neutral300;
      display: inline-block;
      box-shadow: inset 1px 1px 4px 1px rgba(0, 0, 0, 0.16);
      border-radius: 20px;
      position: relative;
      margin-left: 16px;
      top: 2px;
      &::before {
        content: '';
        display: inline-block;
        width: 19px;
        height: 19px;
        border-radius: 19px;
        position: absolute;
        top: -2px;
        left: 0px;
        transition: 0.2s;
        background-image: url('/images/pinRadioIcon.svg');
      }
    }
    .radioCheckInput:checked + .radioCheckLabel {
      background-color: $secon200;
      &::before {
        left: 17px;
      }
    }
  }
}
.talkContentModal {
  color: $neutral800;
  font-size: 14px;
  line-height: 18.2px;
  padding: 15px 18px;
  padding-right: 40px;
  width: 500px;
}
.empty {
  color: $neutral500;
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
  text-align: center;
  margin-top: 200px;
}

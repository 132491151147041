@import '@standby/common-ui/color.scss';

.pageTitle {
  position: relative;
  .pageTopBtn {
    position: absolute;
    right: 0;
    top: 6px;
  }
  .companyName {
    font-weight: 400;
    margin-left: 38px;
  }
}
.checkList {
  li {
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.talkExplain {
  .title {
    color: $neutral800;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.64px;
  }
  .content {
    margin-top: 8px;
    margin-bottom: 24px;
  }
}
.exText {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.14px;
  color: $neutral600;
  padding-left: 10px;
  position: relative;
  &::before {
    content: '*';
    display: inline-block;
    position: absolute;
    top: 3px;
    left: 0;
    color: $neutral600;
    font-size: 14px;
    font-weight: 300;
  }
}
.fileList {
  display: flex;
  margin-top: 14px;
  &:nth-child(1) {
    margin-top: 0;
  }
}

.fileWrap {
  margin-left: 8px;
  &:nth-child(1) {
    margin-left: 0;
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    color: $neutral800;
    margin-bottom: 10px;
    span {
      color: $main300;
    }
  }
}
.inputList {
  .row {
    display: flex;
    color: $neutral800;
    margin-top: 8px;
    &:nth-child(1) {
      margin-top: 0;
    }
    .title {
      width: 115px;
      display: flex;
      align-items: flex-start;
      font-size: 14px;
      padding-top: 10px;
      font-weight: 400;
      &.minus {
        &::before {
          content: '';
          background-image: url('/images/minusIcon.svg');
          display: inline-block;
          width: 7px;
          height: 1px;
          background-position: left top;
          background-repeat: no-repeat;
          padding-left: 10px;
          position: relative;
          top: 9px;
        }
      }
      &.plus {
        &::before {
          content: '';
          background-image: url('/images/plusIcon.svg');
          display: inline-block;
          width: 9px;
          height: 13px;
          background-position: left center;
          background-repeat: no-repeat;
          padding-left: 11px;
          position: relative;
          top: 3px;
        }
      }
      &.equal {
        font-weight: 500;
        &::before {
          content: '';
          background-image: url('/images/equalIcon.svg');
          display: inline-block;
          width: 9px;
          height: 13px;
          background-position: left center;
          background-repeat: no-repeat;
          padding-left: 11px;
          position: relative;
          top: 4px;
        }
      }
    }
    .inputSizeView {
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      font-weight: 400;
      padding-right: 14px;
    }
  }
  .inputListBorderTop {
    border-top: 1px solid $neutral300;
    padding-top: 24px;
  }
}
.requiredMark {
  color: $warning200;
}
.inputs {
  display: flex;
  align-items: center;
  input,
  .inputSizeView {
    width: 298px;
  }
  .inputSubText {
    font-size: 14px;
    margin-left: 6px;
  }
}
.datePicker {
  input {
    width: 298px;
  }
}
.borderTopList {
  border-top: 1px solid $neutral300;
  padding-top: 24px;
}

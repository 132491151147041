@import '@standby/common-ui/color.scss';

.modalContent {
  width: 675px;
  padding: 36px 50px;
  > ul {
    > li {
      display: flex;
      align-items: flex-start;
      > .title {
        width: 144px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        color: $neutral800;
        position: relative;
        top: 7px;
      }
      margin-top: 24px;
      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }
}
.datepicker {
  input {
    width: 298px;
  }
}
.inputMany {
  margin-top: 8px;
  position: relative;
  .plus {
    position: absolute;
    top: 3px;
    right: -19px;
  }
  .minus {
    position: absolute;
    top: 22px;
    right: -19px;
  }
  &:nth-child(1) {
    margin-top: 0;
  }
  .businessTypeAndItem {
    margin-top: 6px;
    display: flex;
    align-items: center;
    > span {
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.56px;
    }
    .type {
      display: inline-block;
      width: 47px;
      flex-shrink: 1;
      margin-right: 4px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.56px;
      .index {
        display: inline-block;
        width: 21px;
      }
    }
    .item {
      display: inline-block;
      width: 47px;
      flex-shrink: 1;
      padding-left: 21px;
      margin-right: 4px;
      color: $neutral800;
      font-size: 14px;
      font-weight: 400;
      line-height: 14px;
      letter-spacing: -0.56px;
    }
  }
}
.checkList {
  li {
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.saveBtn {
  height: 48px;
  background-color: #fff;
  border: 1px solid $main400;
  border-radius: 4px;
  &:disabled {
    border-color: $neutral300;
    background-color: $neutral200;
    &.send {
      span {
        background-image: url('/images/sendTalkIcon_disabled.svg');
      }
    }
    span {
      color: $neutral400;
    }
  }
  &.send {
    span {
      background-image: url('/images/sendTalkIcon.svg');
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 20px;
    }
  }
  span {
    color: $main500;
    font-size: 14px;
    font-weight: 400;
  }
}
.buttonArea {
  display: flex;
  justify-content: space-between;
  margin-top: 36px;
}
.ex {
  color: $neutral600;
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
  position: relative;
  padding-left: 9px;
  margin-top: 8px;
  &::before {
    content: '*';
    position: absolute;
    left: 0;
    top: 4px;
  }
}

@import '@standby/common-ui/color.scss';
.login {
  width: 100%;
  height: 100vh;
  background-color: $primary900;
  padding-top: 105px;
  .loginForm {
    width: 620px;
    background-color: $neutral100;
    border-radius: 8px;
    margin: 0 auto;
    padding: 70px 80px 110px 80px;
    .logo {
      text-align: center;
      margin-bottom: 55px;
    }
    .label {
      font-size: 15px;
      font-weight: 400;
      color: $neutral900;
      margin-bottom: 5px;
    }
    .emailInput {
      position: relative;
      margin-bottom: 13px;
      // input{
      //     padding-right: 100px;
      // }
      // &:after{
      //     content: "@jpac.kr";
      //     position: absolute;
      //     display: inline-block;
      //     top: 10px;
      //     right: 14px;
      //     font-size: 14px;
      //     color: $neutral500;
      // }
    }
    .autoLogin {
      margin-top: 12px;
      span {
        color: $neutral700;
      }
    }
    .btnArea {
      margin-top: 43px;
      .loginBtn {
        width: 100%;
      }
    }
    .link {
      text-align: center;
      margin-top: 12px;
      a {
        color: $neutral500;
        font-size: 15px;
        font-weight: 400;
        &:nth-child(1) {
          margin-right: 21px;
          position: relative;
          &:after {
            position: absolute;
            right: -10px;
            top: 4px;
            width: 1px;
            display: inline-block;
            content: '';
            background-color: $neutral300;
            height: 14px;
          }
        }
      }
    }
  }
}

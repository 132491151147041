@import '@standby/common-ui/color.scss';
.filedownLoad {
  height: 28px;
  border-radius: 2px;
  border: 1px solid $neutral400;
  background: $neutral100;
  color: $main300;
  font-size: 13px;
  padding-left: 30px;
  font-weight: 400;
  background-image: url('/images/fileDownIcon_main300.svg');
  background-repeat: no-repeat;
  background-position: top 6px left 13px;
  padding-right: 13px;
}

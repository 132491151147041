@import '@standby/common-ui/color.scss';

.businessIdEmpty {
  display: block !important;
  .text {
    font-weight: 400;
    color: $neutral900;
    font-size: 16px;
  }
  .searchBtn {
    width: 330px;
    height: 40px;
    border: 1px solid $main400;
    color: $main400;
    background-color: $neutral100;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 20px;
  }
}
.businessCompanyInfo {
  display: block !important;
  font-size: 18px;
  position: relative;
  .companyName {
    color: $neutral800;
    font-weight: 500;
    margin-bottom: 3px;
  }
  .searchCompany {
    position: absolute;
    right: 0;
    top: 28px;
  }
}
.pageTitle {
  position: relative;
  .pageTopBtn {
    position: absolute;
    right: 0;
    top: 6px;
  }
}
.datepicker {
  input {
    width: 298px;
  }
}
.inputWon {
  font-size: 14px;
  color: $neutral800;
  margin-left: 6px;
}
.ex {
  line-height: 18.2px;
  font-size: 14px;
  font-weight: 400;
  color: $neutral600;
  margin-top: 8px;
}
.registrationDate {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $neutral300;
  font-size: 18px;
  font-weight: 400;
  color: $neutral800;
}
.lastStep {
  position: relative;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $neutral300;
  font-size: 18px;
  font-weight: 400;
  color: $neutral800;
  .step {
    font-weight: 500;
  }
  button {
    border: 1px solid $neutral400;
    width: 79px;
    height: 32px;
    border-radius: 4px;
    position: absolute;
    right: 0;
    top: 20px;
    background-color: $neutral100;
    color: $neutral800;
    font-size: 13px;
    font-weight: 400;
  }
}
.messageBox {
  display: flex;
}
.checkList {
  li {
    margin-top: 8px;
    &:first-child {
      margin-top: 0;
    }
  }
}
.businessTypeAndItem {
  display: flex;
  align-items: center;
  margin-top: 6px;
  &:first-child {
    margin-top: 12px;
  }
  .type {
    display: inline-block;
    width: 47px;
    flex-shrink: 1;
    margin-right: 4px;
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.56px;
    .index {
      display: inline-block;
      width: 21px;
    }
  }
  .item {
    display: inline-block;
    width: 47px;
    flex-shrink: 1;
    padding-left: 21px;
    margin-right: 4px;
    color: $neutral800;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.56px;
  }
}
.freeMonthCheckBox {
  display: block;
  margin-top: 3px;
  &:first-child {
    margin-top: 0;
  }
}
.confirmReason {
  display: flex;
  margin-top: 24px;
  > .title {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;
    color: $neutral800;
    margin-right: 12px;
  }
  > .content {
    flex: 1;
    textarea {
      width: 100%;
      height: 78px;
      resize: none;
      border: 1px solid $neutral400;
      border-radius: 4px;
      padding: 11px 14px;
      font-size: 14px;
      font-weight: 400;
      line-height: 130%;
      color: $neutral800;
    }
  }
}

@import '@standby/common-ui/color.scss';
.setupNewPassword {
  width: 100%;
  height: 100vh;
  background-color: $primary900;
  padding-top: 105px;
  .setupNewPasswordForm {
    width: 620px;
    background-color: $neutral100;
    border-radius: 8px;
    margin: 0 auto;
    padding: 70px 80px 110px 80px;
    min-height: 580px;
    .logo {
      text-align: center;
      margin-bottom: 38px;
    }
    .label {
      font-size: 15px;
      font-weight: 400;
      color: $neutral900;
      margin-bottom: 5px;
    }
    .explain {
      text-align: center;
      margin-bottom: 56px;
      .title {
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .inputRow {
      margin-top: 16px;
      .passwordEx {
        text-align: left;
        margin-top: 8px;
        li {
          color: $neutral600;
          font-size: 14px;
          font-weight: 400;
          position: relative;
          line-height: 18.2px;
          padding-left: 9px;
          margin-top: 4px;
          &:nth-child(1) {
            margin-top: 0;
          }
          &:before {
            content: '*';
            display: inline-block;
            position: absolute;
            left: 0;
            top: 4px;
          }
        }
      }
    }
    .btnArea {
      margin-top: 36px;
      .submit {
        width: 100%;
      }
    }
  }
}

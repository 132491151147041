@import '@standby/common-ui/color.scss';

.checkResultList {
  margin-bottom: 100px;
  .result {
    max-width: 940px;
    margin: 0 auto;
    margin-top: 36px;
    > .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;
      color: $neutral900;
      > .subInfo {
        font-size: 14px;
        line-height: 14px;
        color: $neutral600;
        font-weight: 400;
        padding-left: 9px;
        position: relative;
        margin-left: 13px;
        top: -2px;
        &::before {
          content: '*';
          position: absolute;
          left: 0;
          top: 6px;
        }
      }
    }
    > ul {
      border-top: 1px solid $neutral700;
      margin-top: 16px;
      border-bottom: 1px solid $neutral300;
      > li {
        display: flex;
        .ListTitle {
          border-bottom: 1px solid $neutral300;
          width: 240px;
          padding: 24px 0;
          font-weight: 500;
          font-size: 18px;
          color: $primary800;
          line-height: 18px;
          flex-shrink: 0;
          &.notImportant {
            font-size: 16px;
            font-weight: 400;
            color: $neutral800;
          }
        }
        .ListTitle ~ .listContent {
          margin-left: 0;
        }
        .innerListContent {
          .listContent {
            margin-left: 0;
          }
        }
        .innerListContent {
          border-bottom: 1px solid $neutral300;
          flex-grow: 1;
          padding: 24px 0;
          > li {
            margin-top: 24px;
            display: flex;
            &:nth-child(1) {
              margin-top: 0;
            }
            .innerTitle {
              flex-shrink: 0;
              &.inputMiddle {
                position: relative;
                top: 7px;
              }
            }
            .innerContent {
              flex-grow: 1;
              .rowContent {
                font-weight: 400;
                > li {
                  margin-top: 24px;
                  display: flex;
                  &:nth-child(1) {
                    margin-top: 0;
                  }
                  .rowTitle {
                    flex-shrink: 0;
                    width: 115px;
                    &.inputMiddle {
                      position: relative;
                      top: 7px;
                    }
                    &.multiRow {
                      position: relative;
                      top: -2px;
                    }
                  }
                  .rowContent {
                    flex-grow: 1;
                  }
                }
              }
            }
          }
        }
        .radioList {
          li {
            margin-top: 2px;
            &:nth-child(1) {
              margin-top: 0;
            }
          }
        }
        .listContent {
          border-bottom: 1px solid $neutral300;
          flex-grow: 1;
          margin-left: 240px;
          padding: 24px 0;
          font-weight: 400;
          display: flex;
          color: $neutral800;
          &.manyRow {
            display: block;
          }
          .innerTitle {
            width: 190px;
            font-size: 16px;
            line-height: 16px;
            font-weight: 400;
            flex-shrink: 0;
          }
          .innerContent {
            flex-grow: 1;
            position: relative;
          }
          .editSide {
            margin-right: 4px;
          }
          .listRow {
            display: flex;
            margin-top: 12px;
            &:nth-child(1) {
              margin-top: 0;
            }
            .innerContent {
              font-size: 16px;
              line-height: 16px;
              align-self: center;
              .row {
                margin-top: 12px;
                &:nth-child(1) {
                  margin-top: 0;
                }
              }
              .copyText {
                color: $main300;
                text-decoration: underline;
                cursor: pointer;
              }
              .exText {
                color: $neutral600;
                font-size: 14px;
                line-height: 18.2px;
                font-weight: 400;
              }
            }
          }
        }
        &:nth-last-child(1) {
          .listContent,
          .ListTitle,
          .innerListContent {
            border-bottom: none;
          }
        }
        .inputMany {
          margin-top: 6px;
          .btnArea {
            width: 17px;
          }
          position: relative;
          .plus {
            position: absolute;
            top: 3px;
            right: -19px;
          }
          .minus {
            position: absolute;
            top: 22px;
            right: -19px;
          }
          &:nth-child(1) {
            margin-top: 0;
          }
        }
      }
    }
    .talk {
      > .title {
        font-size: 16px;
        line-height: 16px;
        margin-top: 22px;
        color: $neutral800;
        font-weight: 400;
      }
      .sendList {
        margin-top: 12px;
        li {
          margin-top: 8px;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
    .inputs {
      .inputRow {
        display: flex;
        margin-top: 12px;
        &:nth-child(1) {
          margin-top: 0;
        }
        > .title {
          font-size: 14px;
          font-weight: 400;
          color: $neutral800;
          width: 125px;
          line-height: 38px;
        }
        > .content {
          input {
            width: 205px;
          }
        }
      }
    }
  }
}
.saveFixBtn {
  position: absolute;
  bottom: 25px;
  right: 0;
  text-align: right;
  .btn {
    button {
      width: 88px;
      height: 88px;
      background-color: $neutral100;
      border: 1px solid $neutral300;
      box-shadow: 0px 1px 4px 2px rgba(153, 153, 153, 0.25);
      border-radius: 50%;
      color: $main500;
      font-size: 13px;
      font-weight: 400;
      &:disabled {
        color: $neutral400;
        border: 1px solid $neutral300;
        background-color: $neutral200;
        box-shadow: 0px 1px 4px 2px rgba(153, 153, 153, 0.25);
      }
    }
    margin-bottom: 6px;
  }
  .ex.disabled {
    color: $neutral300;
  }
}
.ex {
  color: $neutral600;
  font-size: 14px;
  line-height: 18.2px;
  font-weight: 400;
  position: relative;
  padding-left: 9px;
  &::before {
    content: '*';
    position: absolute;
    left: 0;
    top: 4px;
  }
}
.sendBtn {
  width: 330px;
  height: 40px;
  background-color: #fff;
  border: 1px solid $main400;
  border-radius: 4px;
  margin-top: 12px;
  &:disabled {
    border-color: $neutral300;
    background-color: $neutral200;
    span {
      color: $neutral400;
      background-image: url('/images/sendTalkIcon_disabled.svg');
    }
  }
  span {
    color: $main500;
    font-size: 14px;
    background-image: url('/images/sendTalkIcon.svg');
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 20px;
  }
}

.fileDownBtn {
  background-color: #fff;
  width: 16px;
  height: 16px;
  border: none;
  background-image: url('/images/filedownIcon.svg');
  position: relative;
  top: 2px;
  margin-left: 4px;
}
.outLink {
  color: $main300;
  font-size: 15px;
  line-height: 15px;
  background-image: url('/images/outLinkIcon.svg');
  background-position: right top 3px;
  padding-right: 20px;
  background-repeat: no-repeat;
}
.pageArrowBtn {
  color: $main500;
  font-size: 14px;
  background-color: transparent;
  font-weight: 400;
  background-image: url('/images/mainColorArrow.svg');
  background-repeat: no-repeat;
  background-position: right top 5px;
  padding-right: 11px;
}
.flexList {
  width: 100%;
  > li {
    margin-top: 24px;
    &:nth-child(1) {
      margin-top: 0;
    }
  }
  li {
    display: flex;
    .rowTitle {
      flex-shrink: 0;
      width: 144px;
      position: relative;
      top: -2px;
    }
  }
}
li {
  &.hasBtn {
    position: relative;
    .coverBtnArea {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

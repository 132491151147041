@import '@standby/common-ui/color.scss';

.buttonGroup {
  margin-top: 33px;
  font-size: 16px;
  color: $main500;
  letter-spacing: -0.48px;
  line-height: 100%;
  font-weight: 400;

  display: flex;

  .button {
    min-width: 132px;
    &:disabled {
      cursor: pointer;
    }
  }

  .inactive {
    color: $neutral400;
    background-color: $neutral200;
    border-color: $neutral300;
  }
}

.staffList {
  margin-top: 12px;
  width: 100%;
  th {
    border-left: 1px solid $neutral400;
    border-top: 1px solid $neutral400;
    border-bottom: 1px solid $neutral400;
    background-color: $neutral200;
    &:nth-last-child(1) {
      border-right: 1px solid $neutral400;
    }
    padding: 21px 20px 24px 20px;
  }
  th,
  td {
    font-weight: 400;
    font-size: 14px;
    word-break: keep-all;
    color: $neutral800;
    letter-spacing: -0.42px;
    text-align: left;
    line-height: 100%;
  }
  td {
    height: 49px;
    word-break: keep-all;
    padding: 16px;
    border-left: 1px solid $neutral300;
    border-bottom: 1px solid $neutral300;
    &:nth-last-child(1) {
      padding: 6px 16px;
      border-right: 1px solid $neutral300;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;

      .manageButton {
        font-size: 13px;
        padding: 6px 16px;
        height: 28px;
        word-break: keep-all;

        &Grey {
          @extend .manageButton;
          color: $neutral700;
          border: 1px solid $neutral400;
        }
      }
    }
  }
}

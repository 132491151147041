@import '@standby/common-ui/color.scss';

.modalContent {
  width: 675px;
  min-height: 500px;
  padding: 36px 50px;
  box-sizing: border-box;

  .name {
    color: $neutral800;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
  }

  .updatedAt {
    color: $neutral600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .divider {
    border-top: 1px solid $neutral300;
    margin: 24px 0;
    width: 100%;
  }

  .inputColumn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .row {
      width: 100%;
      display: flex;

      .category {
        flex: 0 1 122px;

        color: $neutral900;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin: auto 0;

        &.fixed {
          margin: 11px 0 0;
        }
      }

      .radioGroup {
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 24px;

        label {
          word-break: keep-all;
          flex: 1 1 auto;
        }
      }

      .input {
        flex: 1 1 auto;
        input {
          width: 100%;
        }
      }

      .checkBoxGroup {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .saveButton {
    margin: 36px auto 0;
    width: 300px;
    height: 48px;
  }
}

@import '@standby/common-ui/color.scss';

.modalContent {
  width: 675px;
  padding: 36px 50px;
  > ul {
    > li {
      display: flex;
      align-items: flex-start;
      margin-top: 24px;
      &:nth-child(1) {
        margin-top: 0;
      }
      > .title {
        width: 144px;
        flex-shrink: 0;
        font-size: 16px;
        font-weight: 400;
        color: $neutral800;
        position: relative;
        top: 7px;
      }
      > .rowContent {
        .textarea {
          width: 298px;
          height: 78px;
          border: 1px solid $neutral400;
          border-radius: 4px;
          color: $neutral800;
          font-size: 14px;
          line-height: 18.2px;
          padding: 11px 14px;
        }
        .datepicker {
          input {
            width: 298px;
          }
        }
      }
    }
  }
}
.buttonArea {
  margin-top: 30px;
  text-align: center;
}
.saveBtn {
  height: 48px;
  background-color: #fff;
  border: 1px solid $main400;
  border-radius: 4px;
  &:disabled {
    border-color: $neutral300;
    background-color: $neutral200;
    span {
      color: $neutral400;
    }
  }
  span {
    color: $main500;
    font-size: 14px;
    font-weight: 400;
  }
}

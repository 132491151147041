@import '@standby/common-ui/color.scss';

.row {
  width: 100%;
  display: flex;

  .category {
    flex: 0 1 122px;
    padding-right: 24px;
    word-break: keep-all;

    color: $neutral900;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    margin: auto 0;
  }

  .input {
    flex: 1 1 auto;
    input {
      width: 100%;
    }
  }
}

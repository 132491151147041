@import '@standby/common-ui/color.scss';

.modalContent {
  width: 675px;
  padding: 36px 50px;
  box-sizing: border-box;

  .detail {
    color: $neutral600;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .inputColumn {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;

    .row {
      width: 100%;
      display: flex;

      .category {
        flex: 0 1 122px;
        padding-right: 24px;
        word-break: keep-all;

        color: $neutral900;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 133.333% */
        margin-top: 11px;
      }

      .input {
        flex: 1 1 auto;
        input {
          width: 100%;
        }
      }
    }
  }

  .button {
    margin: 36px auto 0;
    width: 300px;
    height: 48px;
  }
}

@import '@standby/common-ui/color.scss';

.userSettingBox {
  display: inline-flex;
  .tabBox {
    width: 185px;
    border-right: 1px solid $neutral300;
    .tabList {
      height: 381px;
      text-align: left;
      .tab {
        cursor: pointer;
        padding: 14px 23px 15px;
        width: 100%;
        color: $neutral900;
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        &.active {
          color: $neutral100;
          background-color: $main400;
        }
      }
    }
    .logoutBtn {
      padding: 14px 23px 15px;
      border-top: 1px solid $neutral300;
      button {
        color: $neutral700;
        font-size: 15px;
        font-weight: 400;
        background-color: transparent;
        line-height: 1;
      }
    }
  }
  .activeBox {
    padding: 28px 30px;
    min-width: 590px;
    .activeTitle {
      color: $neutral900;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 16px;
      .explain {
        position: relative;
        padding-left: 9px;
        margin-top: 2px;
        color: $neutral700;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3;
        &:first-child {
          margin-top: 6px;
        }
        &:before {
          content: '*';
          display: inline-block;
          position: absolute;
          top: 3px;
          left: 0;
        }
      }
    }
    .passwoard {
      margin-top: 12px;
      &:first-child {
        margin-top: 0;
      }
    }
    .changeBtn {
      margin-left: 8px;
      padding: 0 15px;
    }
    .termsAllCheckBox {
      width: 407px;
      position: relative;
      .textBtn {
        position: absolute;
        right: 0;
        top: 0;
        color: $neutral500;
        font-size: 14px;
        font-weight: 400;
        line-height: 1;
        text-decoration: underline;
        background-color: transparent;
      }
    }
    .termsCheckBox {
      margin-top: 13px;
      margin-left: 26px;
      padding: 12px 14px;
      width: 381px;
      height: 42px;
      background-color: $neutral200;
      border-radius: 2px;
    }
  }
}
